<template>
    <b-card no-body class="d-flex flex-column p-3" style="row-gap: 16px">
        <div class="d-flex">
            <b-button variant="primary" class="mr-2" @click="handleAction">Print</b-button>
            <b-button variant="success" @click="handleAction">Excel</b-button>
        </div>
        <b-row>
            <b-col cols="12">
                <b-table
                    small
                    show-empty
                    :filter="filter"
                    striped
                    head-variant="light"
                    :items="employees"
                    :fields="[
                        { key: 'customerId', label: 'Customer ID' },
                        { key: 'nationalID', label: 'National ID' },
                        { key: 'firstName', label: 'First Name' },
                        { key: 'lastName', label: 'Last Name' },
                        { key: 'jobDescription', label: 'Job Description' },
                        { key: 'monthlySalary', label: 'Salary' },
                        { key: 'isActive', label: 'Active' },
                        { key: 'isVerified', label: 'Verified' },
                        { key: 'action', label: 'View Profile' },
                    ]"
                >
                    <template #cell(isActive)="data">
                        <b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
                        <b-badge variant="danger" v-else>Archived</b-badge>
                    </template>
                    <template #cell(isVerified)="data">
                        <b-badge variant="success" v-if="data.item.isVerified">Verified</b-badge>
                        <b-badge variant="danger" v-else>Not Verified</b-badge>
                    </template>

                    <template #cell(action)="data">
                        <b-button size="sm" variant="primary" @click="handleView(data.item)">View Detail</b-button>
                    </template>
                </b-table>

                <div class="pt-2 d-flex align-items-center">
                    <b-form-group
                        label="Per page"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="9"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="employees.length"
                            :per-page="perPage"
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
export default {
    name: 'BusinessEmployees',
    data() {
        return {
            employees: [],
            perPage: 5,
            currentPage: 1,
            pageOptions: [5, 10, 15],
            filter: null,
        };
    },
    methods: {
        handleAction() {
            this.$bvToast.toast('This process is still being worked on', {
                title: 'Warning',
                variant: 'warning',
                solid: true,
            });
        },
        async getData() {
            const response = await this.$store.dispatch(
                'customer/employments/get_employeesByCustomerId',
                this.$route.params.customer,
            );
            this.employees = response?.employees ?? [];
        },
        handleView(item) {
            this.$router.push(`/customers/list/${item.customerId}`);
        },
    },

    created() {
        this.getData();
    },
};
</script>
